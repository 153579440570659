<template>
    <div class="performance-statistics-widget">
        <div class="table-responsive">
            <table class="table table-sm">
                <tbody>
                    <tr>
                        <td class="text-left">Sharpe Ratio</td>
                        <td class="text-right">{{items.sharpeRatio | number}}</td>
                        <td class="text-left">Average Winning {{timePeriodLabel}}</td>
                        <td class="text-right">{{items.avgWinningMonth | number(2, true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Sortino Ratio</td>
                        <td class="text-right">{{items.sortinoRatio | number}}</td>
                        <td class="text-left">Average Losing {{timePeriodLabel}}</td>
                        <td class="text-right">{{items.avgLosingMonth | number(2, true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Sterling Ratio</td>
                        <td class="text-right">{{items.sterlingRatio | number}}</td>
                        <td class="text-left">Winning {{timePeriodLabel}}s</td>
                        <td class="text-right">{{items.winningMonths | number(2, true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Calmar Ratio</td>
                        <td class="text-right">{{items.calmarRatio | number}}</td>
                        <td class="text-left">Losing {{timePeriodLabel}}s</td>
                        <td class="text-right">{{items.losingMonths | number(2, true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Skewness</td>
                        <td class="text-right">{{items.skewness | number}}</td>
                        <td class="text-left">Maximum Drawdown</td>
                        <td class="text-right">{{items.maxDrawdown | number(2, true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Kurtosis</td>
                        <td class="text-right">{{items.kurtosis | number}}</td>
                        <td class="text-left">Annualised Return</td>
                        <td class="text-right">{{items.annualisedReturn | number(2, true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Best {{timePeriodLabel}}</td>
                        <td class="text-right">{{items.bestMonth | number(2, true)}}</td>
                        <td class="text-left">Annualised Volatility</td>
                        <td class="text-right">{{items.annualisedVol | number(2, true)}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Worst {{timePeriodLabel}}</td>
                        <td class="text-right">{{items.worstMonth | number(2, true)}}</td>
                        <td class="text-left">Total Return</td>
                        <td class="text-right">{{items.totalReturn | number(2, true)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'performance-statistics',
    props: {
        items: {
            type: Object,
            default: () => {
                return {
                    sharpeRatio: 0,
                    sortinoRatio: 0,
                    sterlingRatio: 0,
                    calmarRatio: 0,
                    skewness: 0,
                    kurtosis: 0,
                    bestMonth: 0,
                    worstMonth: 0,
                    avgWinningMonth: 0,
                    avgLosingMonth: 0,
                    winningMonths: 0,
                    losingMonths: 0,
                    maxDrawdown: 0,
                    annualisedReturn: 0,
                    annualisedVol: 0,
                    totalReturn: 0
                };
            }
        },
        timePeriodLabel: {
            type: String,
            default: ''
        }
    }
}
</script>