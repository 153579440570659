<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Performance Statistics">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 d-flex align-items-center justify-content-end">
                                    <b-form inline @submit.prevent>
                                        <label class="mr-sm-2" for="net-returns-time-period">Time Period:</label>
                                        <b-form-select id="net-returns-time-period" class="mb-2 mr-sm-2 mb-sm-0" v-model="timePeriod" :options="filterOptions.timePeriod" @change="requests"></b-form-select>
                                    </b-form>
                                    <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2" :ranges="dateRanges">
                                        <template v-slot:input="picker" style="min-width: 350px;">
                                            <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                                            <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                                        </template>
                                    </date-range-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Performance Statistics</h4>
                        </div>
                        <div class="card-body">
                            <performance-statistics :items="performanceStatisticItems" :timePeriodLabel="getTimePeriodName()"></performance-statistics>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Gross Returns</h4>
                        </div>
                        <div class="card-body">
                            <net-returns :items="netReturnItems"></net-returns>
                        </div>
                    </div>
                </div>
            </div>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import NetReturns from '@/widgets/NetReturns';
import PerformanceStatistics from '@/widgets/PerformanceStatistics';
import { startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';

export default {
    data() {
        let date = new Date();

        return {
            dateRange: {
                startDate: startOfMonth(date),
                endDate: endOfMonth(date)
            },
            timePeriod: 'Daily',
            filterOptions: {
                timePeriod: [
                    {
                        value: 'Monthly',
                        text: 'Monthly'
                    },
                    {
                        value: 'Weekly',
                        text: 'Weekly'
                    },
                    {
                        value: 'Daily',
                        text: 'Daily'
                    }
                ]
            },
            dateRanges: {
                'This Week': [startOfWeek(date), endOfWeek(date)],
                'This Month': [startOfMonth(date), endOfMonth(date)],
                'Last Month': [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))],
                'This Quarter': [startOfQuarter(date), endOfQuarter(date)],
                'Last Quarter': [startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))],
                'This Year': [startOfYear(date), endOfYear(date)],
                'Last Year': [startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))],
                'All Time': [new Date('2015-12-01'), endOfYear(date)]
            },
            hasSetupListeners: false,
            netReturnItems: [],
            performanceStatisticItems: {}
        };
    },
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        NetReturns,
        DateRangePicker,
        PerformanceStatistics
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('netreturns', this.onPerformanceStatistics);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('netreturns', this.onPerformanceStatistics);
                this.hasSetupListeners = true;
            }

            this.$ws.send({
                accountName: this.$store.state.activeAccount,
                request: 'NetReturns',
                args: {
                    startDate: format(this.dateRange.startDate, 'yyyy-MM-dd'),
                    endDate: format(this.dateRange.endDate, 'yyyy-MM-dd'),
                    timePeriod: this.timePeriod
                }
            });
        },
        onPerformanceStatistics(event) {
            let netReturnItems = [];
            for(let date in event.response.Returns) {
                netReturnItems.push({
                    timestamp: new Date(date),
                    netReturn: event.response.Returns[date]
                });
            }

            this.netReturnItems = netReturnItems;
            this.performanceStatisticItems = event.response.PerformanceStatistics;
        },
        getTimePeriodName() {
            if(this.timePeriod === 'Daily') {
                return 'Day';
            }

            if(this.timePeriod === 'Weekly') {
                return 'Week';
            }

            return 'Month';
        }
    }
}
</script>