<template>
    <div class="net-returns-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
            </div>
        </div>
        <b-table id="trades-table" :items="filterItems()" :fields="columns" responsive class="text-center"
            show-empty :per-page="50" :current-page="currentPage">
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <input type="text" v-model="filters[field.key]" placeholder="Search..." class="form-control" />
                </td>
            </template>
            <template slot="empty">
                No results found
            </template>
        </b-table>
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="50" aria-controls="trades-table"></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns';
import { map, clone } from 'lodash';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'net-returns',
    data() {
        let that = this;

        return {
            columns: [
                {
                    key: 'timestamp',
                    label: 'Timestamp',
                    sortable: true,
                    formatter: function(value) {
                        return format(value, 'MMM dd, yyyy');
                    }
                },
                {
                    key: 'netReturn',
                    label: 'Net Return',
                    sortable: true,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value, 2, true));
                    }
                }
            ],
            currentPage: 1,
            filters: {
                timestamp: '',
                netreturn: ''
            }
        };
    },
    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    methods: {
        onNetReturns(event) {
            let items = [];
            for(let date in event.response) {
                items.push({
                    timestamp: new Date(date),
                    netReturn: event.response[date]
                });
            }

            this.items = items;
        },
        filterItems() {
            let filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()));
            });

            if(filtered.length > 0) {
                return filtered;
            } else {
                let emptyFilters = {};
                for(let key in this.filters) {
                    emptyFilters[key] = '';
                }

                return [];
            }
        },
        onExport() {
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.items, clone);

            for(let item of items) {
                item.timestamp = format(item.timestamp, 'yyyy-MM-dd HH:mm:ss');
            }

            exportCsv(headers, items, 'NetReturns');
        }
    }
}
</script>